@tailwind base;
@tailwind components;
@tailwind utilities;

.neon-glow {
  text-shadow: 0 0 5px #6a0dad, 0 0 10px #6a0dad, 0 0 20px #6a0dad;
}

/* Add this to your global CSS */
.neon-text {
  color: #ffffff;
  text-shadow: 0 0 8px #a855f7, 0 0 12px #d946ef;
}

/* Add this custom style */
.gradient-border {
  border: 2px solid;
  border-image: linear-gradient(135deg, #8b5cf6, #d946ef) 1;
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.2);
}

.custom-divider {
  height: 4px; /* Adjust thickness here */
  background: linear-gradient(to right, #c05af0, #5d0079); /* Gradient color */
  margin: 2rem 0; /* Optional spacing */
  border: none; /* Remove border styling */
}

@keyframes glow {
  0%,
  100% {
    text-shadow: 0 0 10px #6b46c1, 0 0 20px #6b46c1, 0 0 30px #6b46c1;
  }
  50% {
    text-shadow: 0 0 20px #6b46c1, 0 0 40px #6b46c1, 0 0 50px #6b46c1;
  }
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes pulse-bar {
  0%,
  100% {
    box-shadow: 0 0 10px rgba(239, 68, 68, 0.8);
  }
  50% {
    box-shadow: 0 0 20px rgba(239, 68, 68, 1);
  }
}

@keyframes glow {
  0%,
  100% {
    text-shadow: 0 0 5px rgba(255, 0, 0, 0.8), 0 0 15px rgba(255, 0, 0, 0.6);
  }
  50% {
    text-shadow: 0 0 10px rgba(255, 0, 0, 1), 0 0 20px rgba(255, 0, 0, 0.8);
  }
}

.animate-glow {
  animation: glow 1s infinite;
}

.animate-pulse-bar {
  animation: pulse-bar 1s infinite;
}

.animate-shake {
  animation: shake 0.5s ease-in-out infinite;
}

.hover\:shadow-glow:hover {
  box-shadow: 0 0 5px #6a0dad, 0 0 10px #6a0dad;
}
.hover\:scale-105:hover {
  transform: scale(1.05);
}
.hover\:scale-102:hover {
  transform: scale(1.02);
}

.bg-animate-gradient {
  background-size: 400% 400%;
  animation: gradientMove 20s ease infinite;
}

.card-container {
  perspective: 1000px;
  cursor: pointer;
}

.card-inner {
  position: relative;
  width: 100%;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.flipped .card-inner {
  transform: rotateY(180deg);
}

.card-front {
  position: absolute;
  width: 100%;
  backface-visibility: hidden;
}

.card-back {
  position: absolute;
  width: 100%;
  backface-visibility: hidden;
}

.card-back {
  transform: rotateY(180deg);
}

@keyframes gradientMove {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

input:focus {
  box-shadow: 0 0 10px rgba(128, 90, 213, 0.8);
  border-color: #805ad5;
}
